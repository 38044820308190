import netteForms from 'nette-forms';

/**
 * Display error messages.
 */
netteForms.showFormErrors = function (form, errors) {
	let inputValidation = true;
	/*
	if (form.classList.contains('needs-validation')) {
		form.classList.add('was-validated');
		inputValidation = false;
	}
	 */

	let focusElem,
		elemMessages = {};

	Array.from(form.elements).forEach(elem => {
		elemMessages[elem.id] = {
			messages: [],
			elem,
		};

		elem.classList.remove('is-invalid');

		let invalidFeedback = elem.parentElement.querySelector('.invalid-feedback');
		if (invalidFeedback) {
			invalidFeedback.remove();
		}
	});

	for (let i = 0; i < errors.length; i++) {
		let elem = errors[i].element,
			message = errors[i].message;

		elemMessages[elem.id].messages.push(message);

		if (!focusElem && elem.focus) {
			focusElem = elem;
		}
	}

	for (const [_, item] of Object.entries(elemMessages)) {
		if (item.messages.length > 0) {
			if (inputValidation) {
				item.elem.classList.add('is-invalid');
			}
			let formGroup = item.elem.parentElement;
			let invalidFeedbackElement = document.createElement('div');
			invalidFeedbackElement.classList.add('invalid-feedback');

			invalidFeedbackElement.textContent = item.messages.join('\n');
			formGroup.appendChild(invalidFeedbackElement);
		}
	}

	if (focusElem) {
		focusElem.focus();
	}
};

let netteInitForm = netteForms.initForm;
netteForms.initForm = function (form) {
	netteInitForm(form);

	Array.from(form.elements).forEach(element => {
		element.addEventListener('input', (_) => {
			element.classList.remove('is-invalid');
		});
	});
};

export default netteForms;
