import 'bootstrap';
import '../scss/styles.scss'

import netteForms from './form.js';
import naja from 'naja';

netteForms.initOnLoad();
naja.initialize();

function showPassword(elem, show) {
	let x = elem.closest('.input-group');
	if (!x) {
		return;
	}

	let input = x.querySelector('input');
	let svgIconLink = elem.parentElement.querySelector('svg > use');
	let svgLinkHref = svgIconLink.getAttribute('xlink:href').split('.svg#')[0];
	if (show) {
		svgIconLink.setAttribute('href', svgLinkHref + '.svg#bi-eye-slash');
		input.type = "text";
	} else {
		svgIconLink.setAttribute('href', svgLinkHref + '.svg#bi-eye');
		input.type = "password";
	}
}

document.querySelectorAll('form .show-password').forEach(elem => {
	elem.addEventListener('mousedown', function () {
		showPassword(elem, true);
	});
	elem.addEventListener('mouseup', function () {
		showPassword(elem, false);
	});
	elem.addEventListener('mouseout', function () {
		showPassword(elem, false);
	});
	elem.addEventListener('click', function (e) {
		e.preventDefault();
		e.stopPropagation();
	});
	elem.addEventListener('touchstart', function () {
		showPassword(elem, true);
	});
	elem.addEventListener('touchmove', function () {
		showPassword(elem, false);
	});
	elem.addEventListener('touchend', function () {
		showPassword(elem, false);
	});
	elem.addEventListener('touchcancel', function () {
		showPassword(elem, false);
	});
});
